// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-home-components-five-reasons-tsx": () => import("./../../../src/pages/home/components/five-reasons.tsx" /* webpackChunkName: "component---src-pages-home-components-five-reasons-tsx" */),
  "component---src-pages-home-components-grain-overlay-tsx": () => import("./../../../src/pages/home/components/grain-overlay.tsx" /* webpackChunkName: "component---src-pages-home-components-grain-overlay-tsx" */),
  "component---src-pages-home-components-history-group-tsx": () => import("./../../../src/pages/home/components/history-group.tsx" /* webpackChunkName: "component---src-pages-home-components-history-group-tsx" */),
  "component---src-pages-home-components-intro-tsx": () => import("./../../../src/pages/home/components/intro.tsx" /* webpackChunkName: "component---src-pages-home-components-intro-tsx" */),
  "component---src-pages-home-components-request-letter-tsx": () => import("./../../../src/pages/home/components/request-letter.tsx" /* webpackChunkName: "component---src-pages-home-components-request-letter-tsx" */),
  "component---src-pages-home-components-splash-tsx": () => import("./../../../src/pages/home/components/splash.tsx" /* webpackChunkName: "component---src-pages-home-components-splash-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

